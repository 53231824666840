.about-us {
  padding-bottom: 100px;

  .neon-btn {
    border-color: #FB67FF;
    box-shadow: 0 0 15px 10px #AF42B3, inset 0 0 15px 5px #AF42B3;

    &:hover {
      color: #FB67FF;
    }
  }
}
.about-us-description {
  padding-top: 50px;
  padding-bottom: 100px;
  h2 {
    text-align: left !important;
    margin-bottom: 20px !important;
  }
  p {
    font-size: 14px;
    line-height: 1.5;
    max-width: 1000px;
  }
}
.about-us-banner {
  position: relative;
  height: 450px;

  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    box-shadow: inset 0px -15px 40px #172135;
  }

  img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.about-us-partners {
  +.about-us-partners {
    padding-top: 75px;
  }
  h3 {
    text-align: center !important;
    text-transform: uppercase !important;
    font-size: 21px !important;
    margin-bottom: 30px !important;
    color: #17E6CD !important;
  }
  img {
    max-width: 100%;
    object-fit: contain;


    @media screen and (max-width: 1024px){
      width: 100px;
    }
  }
  &__list {
    >div {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      gap: 15px;
      @media screen and (max-width: 1024px) {
        gap: 30px;
      }
    }
  }


  &--pay {
    h3 {
      color: #E914C2 !important;
    }

    .about-us-partners {
      &__list {
        div + div {
          padding-top: 30px;
        }
      }
    }
  }
}