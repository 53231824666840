.our-products {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
  margin-top: 35px;

  &__wrap {
    width: calc(100% / 3);
    padding:  0 15px;
    padding-bottom: 30px;

    @include media("<1200px") {
      width: 100%;
    }
  }
}

.our-product {
  background-color: $darkBox;
  color: $alwaysWhite;
  border-radius: 14px;
  padding: 15px 24px;

  &__title {
    margin-bottom: 5px;
    font-size: 24px;
    color: $alwaysWhite;
    font-weight: bold;

  }
  &__text {
    color: $alwaysGrey;
    font-size: 14px;
    margin-bottom: 0;
  }
}