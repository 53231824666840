.btn {
  @include commonBtnStyles;
  @include btn-primary;

  &--border {
    @include btn-border;
  }
  &--second {
    @include btn-secondary;
  }
}

.ant {
  &-btn {
    font-weight: 500;
    line-height: 8px;
    text-transform: uppercase;

    &-lg {
      height: 45px;
    }

    &-circle.ant-btn-lg {
      height: 45px;
      width: 45px;
    }

    &-round {
      display: inline-flex;
      align-items: center;

      &.ant-btn-lg {
        font-size: 12px;
        padding: 10px 40px;
        height: 45px;
      }
    }

    &-primary {
      @include btn-primary;
      border: 1px solid $primaryColor;
    }

    &-secondary {
      @include btn-secondary;
    }

    &-tertiary {
      @include btn-tertiary;
    }

    &-default {
      @include btn-default;
    }

    &-with-icon {
      @include with-icon;
    }

    &-loading {
      @include loading;
    }
  }
}


.neon-btn {
  font-size: 18px;
  min-width: 180px;
  border-radius: 8px;
  border: 3px solid #FFE1AE;
  display: inline-flex;
  box-shadow: 0 0 15px 10px #8C641D, inset 0 0 15px 5px #8C641D;
  text-align: center;
  justify-content: center;
  text-transform: uppercase;
  padding: 10px 20px;
  color: #fff;
  font-weight: 600;
  
  &:hover {
    color: #FFE1AE;
  }


}