.footer {
  left: 0;
  bottom: 0;
  width: 100%;
  background: #141b2b;

  &__wrapper {
    display: flex;
    align-items: center;
    padding: 30px 0;
  }
  &__info {
    font-weight: 900;
    font-size: 14px;
    line-height: 16px;
    color: $white;
  }

  &__menu {
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    list-style: none;
    padding: 0;
    margin: 0;
    margin-left: auto;

    &-item {
      font-size: 12px;
      line-height: 16px;
      font-weight: 500;
      color: #fff;
      padding-left: 10px;
      a {
        color: #fff;
        &:hover {
          color: $primaryColor;
        }
      }

      &:hover {
        a::after {

        }
      }
    }
  }

  @include maxWidth(1074px) {
    height: fit-content;
    &__wrapper {
      padding: 26px 32px 25px;
      flex-direction: column;
    }
    &__menu {
      height: fit-content;
      margin: 0 0 20px;
      padding: 0;
      justify-content: center;
      flex-wrap: wrap;
      &:before {
        display: none;
      }
      &-item {
        margin: 0 15px 10px 0;
      }
    }
    &__info {
      order: 2;
      margin-top: 10px;
    }
  }

  @include maxWidth(500px) {
    &__wrapper {
      padding: 26px 16px 25px;
    }
    &__menu {
      flex-direction: column;
      flex-wrap: nowrap;
      &-item {
        margin: 0 0 10px 0;
      }
    }
  }
}