

.conditions {
  &__content {
    max-width: 1200px;
    margin: 20px auto;
    color: $white;
    font-size: 16px;

    > ol {
      >li {
        +li {
          margin-top: 20px;
        }
        >* {
          font-size: 13px;
          >li {
            +li{
              margin-top: 5px;
            }
          }

        }

      }
    }
    ul, ol {
      margin: 5px 0 15px;
    }



    ol {
      counter-reset: item;
      >li {
        display: block;
        &::before {
          content: counters(item, ".") ". ";
          counter-increment: item;
        }
      }
    }

  }
}
