@import "vars";
@import "mixins";
@import "../assets/styles/helpers";
@import "layouts/main";
@import "common/general";
@import "ui/index.scss";
@import "modules/index.scss";
@import "landing/index";
@import "../assets/styles/main";

body {
  background: $main_bg;
  overflow-x: auto;
}

html,
body,
#root {
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
}

* {
  font-family: $titleFont, sans-serif;
  box-sizing: border-box;
}

body {
  margin: 0;
}

body.modal-opened {
  overflow: hidden;
}

.landing-pages {
  font-family: $mainSansSerif;
  color: #fff;

  h1 {
    margin: 0;
    font-family: $titleFont, sans-serif;
    text-align: center;
    font-size: 35px;
    font-weight: 900;
    line-height: 41px;
    color: $white;
  }

  h2 {
    margin: 0;
    font-family: $titleFont, sans-serif;
    text-align: center;
    font-size: 30px;
    font-weight: bold;
    line-height: 36px;
    color: $white;
  }

  h3 {
    margin: 0;
    font-family: $titleFont, sans-serif;
    text-align: center;
    font-size: 25px;
    font-weight: bold;
    line-height: 28px;
    color: $white;
  }
}
