

.testimonials {
  align-content: center;
  margin: 40px 0 100px 0;

  .ant-card-head {
    background-color: $darkInput;
    border-radius: 10px 10px 0 0;
    color: $white;
  }

  .testimonial-card {
    margin: 15px 20px;
    &.ant-card {

      background: $grayBackground;
      color: $white;
      &.ant-card-bordered {
        border-color: $darkInput;
      }
    }
  }
}
