
.benefits-grid {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -12px;
  padding: 0;
  list-style: none;

  &__item {
    width: calc(100% / 3);
    padding: 0 12px;
    padding-bottom: 24px;

    @include media('<=860px') {
      width: 100%;
    }

  }
}

.benefit-card {
  background-color: $darkBox;
  height: 175px;
  border-radius: 10px;
  position: relative;
  font-size: 14px;
  line-height: 1.3;
  font-weight: bold;
  display: flex;
  align-items: center;
  color: #fff;
  flex-direction: column;
  text-align: center;
  justify-content: flex-start;
  padding-top: 25px;
  @include media("<568px") {
    font-size: 14px;
  }
  &__title {
    margin: 0;
    max-width: 175px;
  }
  &__icon {
    width: 90px;
    height: 75px;
    flex: none;
    margin-bottom: 10px;

    img {
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
    }

    @include media("<568px") {
      left: 20px;
      width: 75px;

      img {
        max-width: 100%;
      }
    }
  }
}