.commission-detail-table {
  width: 770px;
  max-width: 95%;
  background: $darkBox;
  color: $alwaysWhite;
  border-radius: 10px;
  padding: 24px;
  font-size: 16px;

  table {
    width: 100%;

    thead {
      th {
        height: 45px;
        border-bottom: 1px solid rgba($alwaysWhite, .2);
        font-weight: 700;
        font-size: 18px;

        &:last-child {
          padding-left: 15px;
          border-left: 1px solid rgba($alwaysWhite, .2);
        }
      }
    }
    tbody {
      tr {
        height: 45px;
        border-bottom: 1px solid rgba($alwaysWhite, .2);

        td {
          &:last-child {
            padding-left: 15px;
            border-left: 1px solid rgba($alwaysWhite, .2);
          }
        }

        &:last-child {
          border-bottom: none;
        }
      }
    }
  }
}