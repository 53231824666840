.faq-card-nav {
  padding: 10px 15px;
  cursor: pointer;
  font-size: 21px;
  font-weight: 700;


  @include media("<1200px") {
    width: 100%;
    font-size: 16px;
    padding: 5px 10px;
    +* {
      margin-top: 10px;
    }
  }
}
.faq-nav-wrap {
  display: flex;
  padding-bottom: 15px;
  gap: 10px;

  @include media("<1200px") {
    display: block;
  }
}
.faq-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  >* {
    width: 100%;
    @include media("<1200px") {
      width: 100%;
    }
  }
}
.faq-item {
  &__title {
    font-weight: 700;
    margin-bottom: 10px;
    font-size: 16px;
  }
  &__text {
    font-size: 12px;
    font-style: italic;
    opacity: .75;
  }
}